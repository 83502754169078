import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../../components/ES/layout'
import BannerLanding from '../../components/ES/BannerLanding'

import pic08 from '../../assets/images/lor2.jpg'
import pic09 from '../../assets/imgs/landing/p1.jpg'

import pic10 from '../../assets/imgs/landing/p2.jpg'
import pic11 from '../../assets/imgs/landing/q1.jpg'

const Landing = (props) => (
    <Layout>
        <Helmet>
            <title>Unidans Project</title>
            <meta name="description" content="Landing Page" />
        </Helmet>

        <BannerLanding />

        <div id="main">
            <section id="one">
                <div className="inner">
                    <p style ={{ textAlign: "justify" }}>UNIDANS es un proyecto complejo que involucre a socios de tres países europeos y se centra
principalmente en el intercambio transnacional de experiencias relacionadas con la danza
adaptada/unificada. El proyecto contempla la participación de alrededor de 100 entrenadores
de danza y voluntarios/país, los cuales van a ser formados en el ámbito de la danza
adaptada/unificada, y la de unas 20 entidades no lucrativas/país que trabajen en el ámbito de
la inclusión social de personas con discapacidad y/o la danza/artes escénicas. Todos ellos van a
tener la oportunidad de formar parte de eventos locales de networking UNIDANS.</p>

                </div>
            </section>
            <section id="two" className="spotlights">
                <section>
                    <Link to="/ES/generic" className="image">
                        <img src={pic08} alt="" />
                    </Link>
                    <div className="content">
                        
                        <div className="inner">
                            <header className="major">
                                <h3>Objetivo del proyecto</h3>
                            </header>
                            <p>El objetivo del proyecto es el de promover la educación
en/a través del deporte, parando especial atención al desarrollo de habilidades por parte de
los entrenadores que trabajan en el ámbito del deporte adaptado/unificado en general, y de la
danza adaptada/unificada en particular.</p>
                            <ul className="actions">
                                <li><Link to="/ES/generic" className="button">Más información</Link></li>
                            </ul>
                        </div>
                    </div>
                </section>
                 <section>
                    <Link to="/generic" className="image">
                        <img src={pic09} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Objetivos</h3>
                            </header>
                            <ul className="actions">
                                <li><Link to="/ES/objectives" className="button">Más información</Link></li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section>
                    <Link to="/generic" className="image">
                        <img src={pic10} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Actividades</h3>
                            </header>
                            <ul className="actions">
                                <li><Link to="/ES/activities" className="button">Más información</Link></li>
                            </ul>
                        </div>
                    </div>
                </section> 
                <section>
                    <Link to="/generic" className="image">
                        <img src={pic11} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>A quién nos dirigimos </h3>
                            </header>
                            <ul className="actions">
                                <li><Link to="/ES/address" className="button">Más información</Link></li>
                            </ul>
                        </div>
                    </div>
                </section> 
            </section>
        </div>

    </Layout>
)

export default Landing